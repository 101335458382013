<template>
  <NodePage v-if="node" v-bind="node" hide-title />
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

defineOptions({
  name: 'Homepage',
})

definePageMeta({
  name: 'homepage',
})

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncGraphqlQuery(
  'route',
  {
    path: nuxtRoute.path,
  },
  {
    transform: (v) => v.data,
    graphqlCaching: {
      client: true,
    },
  },
)

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

setLanguageLinksFromRoute(query.value)
await renderPageDependencies()
</script>
